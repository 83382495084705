import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack @width='full' @alignItems='center' class='mb-3'>\n  <AkBreadcrumbs::AutoTrail />\n</AkStack>\n\n<AkStack\n  @width='full'\n  local-class='header-security-file-search-page-title'\n  @alignItems='center'\n  class='mb-4'\n>\n  <AkStack @direction='column' @spacing='0.5'>\n    <AkTypography @variant='h5' data-test-security-file-search-list-heading>\n      List of Files\n    </AkTypography>\n\n    <AkTypography\n      @variant='body2'\n      data-test-security-file-search-list-description\n    >\n      Here you can view all the files of a selected project.\n    </AkTypography>\n  </AkStack>\n</AkStack>", {"contents":"<AkStack @width='full' @alignItems='center' class='mb-3'>\n  <AkBreadcrumbs::AutoTrail />\n</AkStack>\n\n<AkStack\n  @width='full'\n  local-class='header-security-file-search-page-title'\n  @alignItems='center'\n  class='mb-4'\n>\n  <AkStack @direction='column' @spacing='0.5'>\n    <AkTypography @variant='h5' data-test-security-file-search-list-heading>\n      List of Files\n    </AkTypography>\n\n    <AkTypography\n      @variant='body2'\n      data-test-security-file-search-list-description\n    >\n      Here you can view all the files of a selected project.\n    </AkTypography>\n  </AkStack>\n</AkStack>","moduleName":"irene/components/security/file-search-list/header/index.hbs","parseOptions":{"srcName":"irene/components/security/file-search-list/header/index.hbs"}});
import Component from '@glimmer/component';

export default class SecurityFileSearchListHeaderComponent extends Component {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Security::FileSearchList::Header': typeof SecurityFileSearchListHeaderComponent;
  }
}
