export default {
  "appknox-wrapper-root": "_appknox-wrapper-root_1nb2o5",
  "expanded": "_expanded_1nb2o5",
  "collapsed": "_collapsed_1nb2o5",
  "dashboard-main": "_dashboard-main_1nb2o5",
  "dashboard-header": "_dashboard-header_1nb2o5",
  "dashboard-sidenav": "_dashboard-sidenav_1nb2o5",
  "lower-menu-chat": "_lower-menu-chat_1nb2o5",
  "divider": "_divider_1nb2o5",
  "top-nav-content": "_top-nav-content_1nb2o5",
  "menu-item-text": "_menu-item-text_1nb2o5"
};
