import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack @direction='column' @width='full'>\n  <AkBreadcrumbs::AutoTrail />\n\n  <Security::FileDetailsActions @file={{@file}} />\n\n  <Security::AnalysisList @file={{@file}} />\n</AkStack>", {"contents":"<AkStack @direction='column' @width='full'>\n  <AkBreadcrumbs::AutoTrail />\n\n  <Security::FileDetailsActions @file={{@file}} />\n\n  <Security::AnalysisList @file={{@file}} />\n</AkStack>","moduleName":"irene/components/security/file-details/index.hbs","parseOptions":{"srcName":"irene/components/security/file-details/index.hbs"}});
import Component from '@glimmer/component';
import type SecurityFileModel from 'irene/models/security/file';

export interface SecurityFileDetailsComponentSignature {
  Args: {
    file: SecurityFileModel;
  };
}

export default class SecurityFileDetailsComponent extends Component<SecurityFileDetailsComponentSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Security::FileDetails': typeof SecurityFileDetailsComponent;
  }
}
