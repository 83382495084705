export default {
  "side-menu-root": "_side-menu-root_16al3c",
  "expanded": "_expanded_16al3c",
  "divider": "_divider_16al3c",
  "collapsed": "_collapsed_16al3c",
  "side-menu-list": "_side-menu-list_16al3c",
  "app-logo": "_app-logo_16al3c",
  "favicon-logo": "_favicon-logo_16al3c",
  "no-switcher": "_no-switcher_16al3c",
  "scroll-container": "_scroll-container_16al3c",
  "menu-item-tooltip": "_menu-item-tooltip_16al3c",
  "menu-item-link": "_menu-item-link_16al3c",
  "active": "_active_16al3c",
  "menu-item-text": "_menu-item-text_16al3c",
  "menu-item-badge": "_menu-item-badge_16al3c",
  "menu-item-icon": "_menu-item-icon_16al3c",
  "menu-item-icon-svg": "_menu-item-icon-svg_16al3c",
  "lower-menu": "_lower-menu_16al3c",
  "pendo-icon": "_pendo-icon_16al3c",
  "pendo-ak-icon": "_pendo-ak-icon_16al3c",
  "rotated-icon": "_rotated-icon_16al3c",
  "lower-menu-chat": "_lower-menu-chat_16al3c",
  "no-hover": "_no-hover_16al3c"
};
