import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div local-class='file-details-root'>\n  <AkBreadcrumbs::AutoTrail\n    class='mt-2 mb-3'\n    data-test-fileDetails-breadcrumbContainer\n  />\n\n  <AkStack @direction='column' @spacing='2.5'>\n    <FileDetails::Summary @file={{@file}} />\n\n    <FileDetails::ScanActions @file={{@file}} />\n\n    <FileDetails::InsightsWrapper @file={{@file}} />\n  </AkStack>\n\n  <FileDetails::VulnerabilityAnalysis @file={{@file}} />\n</div>", {"contents":"<div local-class='file-details-root'>\n  <AkBreadcrumbs::AutoTrail\n    class='mt-2 mb-3'\n    data-test-fileDetails-breadcrumbContainer\n  />\n\n  <AkStack @direction='column' @spacing='2.5'>\n    <FileDetails::Summary @file={{@file}} />\n\n    <FileDetails::ScanActions @file={{@file}} />\n\n    <FileDetails::InsightsWrapper @file={{@file}} />\n  </AkStack>\n\n  <FileDetails::VulnerabilityAnalysis @file={{@file}} />\n</div>","moduleName":"irene/components/file-details/index.hbs","parseOptions":{"srcName":"irene/components/file-details/index.hbs"}});
import Component from '@glimmer/component';
import FileModel from 'irene/models/file';

export interface FileDetailsSignature {
  Args: {
    file: FileModel;
  };
}

export default class FileDetailsComponent extends Component<FileDetailsSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    FileDetails: typeof FileDetailsComponent;
  }
}
