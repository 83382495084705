export default {
  "switcher": "_switcher_mbk3kf",
  "collapsed": "_collapsed_mbk3kf",
  "switcher-menu-tooltip": "_switcher-menu-tooltip_mbk3kf",
  "switcher-menu-container": "_switcher-menu-container_mbk3kf",
  "switcher-menu-item": "_switcher-menu-item_mbk3kf",
  "switcher-menu-title": "_switcher-menu-title_mbk3kf",
  "switcher-menu-item-icon": "_switcher-menu-item-icon_mbk3kf",
  "switcher-popover-arrow": "_switcher-popover-arrow_mbk3kf"
};
