import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  local-class='\n  page-wrapper-root \n  page-wrapper-background-{{or @backgroundColor \"dark\"}}'\n  ...attributes\n>\n  <div local-class='page-wrapper-container'>\n    {{yield}}\n  </div>\n</div>", {"contents":"<div\n  local-class='\n  page-wrapper-root \n  page-wrapper-background-{{or @backgroundColor \"dark\"}}'\n  ...attributes\n>\n  <div local-class='page-wrapper-container'>\n    {{yield}}\n  </div>\n</div>","moduleName":"irene/components/page-wrapper/index.hbs","parseOptions":{"srcName":"irene/components/page-wrapper/index.hbs"}});
import Component from '@glimmer/component';

interface PageWrapperSignature {
  Element: HTMLElement;
  Args: {
    backgroundColor?: 'dark' | 'inherit';
  };
  Blocks: {
    default: [];
  };
}

export default class PageWrapperComponent extends Component<PageWrapperSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    PageWrapper: typeof PageWrapperComponent;
  }
}
