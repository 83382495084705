import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<ProjectPreferencesOld::Provider\n  @file={{@file}}\n  @project={{@project}}\n  @profileId={{@profileId}}\n  @platform={{@platform}}\n  as |dpContext|\n>\n  {{yield\n    (hash\n      DevicePreferenceComponent=(component\n        'project-preferences-old/device-preference' dpContext=dpContext\n      )\n    )\n  }}\n</ProjectPreferencesOld::Provider>", {"contents":"<ProjectPreferencesOld::Provider\n  @file={{@file}}\n  @project={{@project}}\n  @profileId={{@profileId}}\n  @platform={{@platform}}\n  as |dpContext|\n>\n  {{yield\n    (hash\n      DevicePreferenceComponent=(component\n        'project-preferences-old/device-preference' dpContext=dpContext\n      )\n    )\n  }}\n</ProjectPreferencesOld::Provider>","moduleName":"irene/components/project-preferences-old/index.hbs","parseOptions":{"srcName":"irene/components/project-preferences-old/index.hbs"}});
import Component from '@glimmer/component';
import { WithBoundArgs } from '@glint/template';

import ProjectModel from 'irene/models/project';
import ProjectPreferencesDevicePreferenceComponent from './device-preference';
import FileModel from 'irene/models/file';

export interface ProjectPreferencesOldSignature {
  Args: {
    file?: FileModel | null;
    project?: ProjectModel | null;
    profileId?: number | string;
    platform?: number;
  };
  Blocks: {
    default: [
      {
        DevicePreferenceComponent: WithBoundArgs<
          typeof ProjectPreferencesDevicePreferenceComponent,
          'dpContext'
        >;
      },
    ];
  };
}

export default class ProjectPreferencesOldComponent extends Component<ProjectPreferencesOldSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    ProjectPreferencesOld: typeof ProjectPreferencesOldComponent;
  }
}
